import * as React from "react";
import { INSTAGRAM_URL, TWITTER_URL } from "settings";
import {
  InstagramIcon,
  LocationIcon,
  MailIcon,
  PhoneIcon,
  TwitterIcon,
} from "components/Icons";

import {
  contactWrapper,
  contactInformation,
  contactInformationRow,
  contactInformationFooter,
  contactChildren,
} from "./ContactInformation.module.scss";

interface ContactInformationProps {
  children?: React.ReactNode;
}

export function ContactInformation(props: ContactInformationProps) {
  return (
    <div className={contactWrapper}>
      <div className={contactInformation}>
        <h1>Leniolabs_ LLC</h1>
        <div className={contactInformationRow}>
          <div>
            <LocationIcon />
          </div>
          <div>7901 4th St N Suite 300, St. Petersburg, 33702</div>
        </div>
        <div className={contactInformationRow}>
          <div>
            <MailIcon />
          </div>
          <div>
            <a href="mailto:info@leniolabs.com">info@leniolabs.com</a>
          </div>
        </div>
        <div className={contactInformationRow}>
          <div>
            <PhoneIcon />
          </div>
          <div>541-288-4033</div>
        </div>
        <div className={contactInformationFooter}>
          {TWITTER_URL && (
            <a href={TWITTER_URL}>
              <TwitterIcon />
            </a>
          )}
          {INSTAGRAM_URL && (
            <a href={INSTAGRAM_URL}>
              <InstagramIcon />
            </a>
          )}
        </div>
      </div>
      <div className={contactChildren}>{props.children}</div>
    </div>
  );
}
